<template>
  <div id="dialog-idea-settings">
    <!-- Dialog -->
    <ib-dialog
      fullscreen
      :visible="visible"
      class="dialog-idea-settings"
      :show-close="false"
      width="960px"
      top="5vh"
      @open="onDialogOpen"
      @close="closeDialog"
      @closed="onDialogClosed"
    >
      <template #title>
        <stepper-pagination
          v-if="!updateSettings && firstTime"
          :additional-text="$t('pages.ideaSettings.title')"
          color="blue"
          class="text-uppercase mb-2 mb-md-4"
          :number-of-steps="4"
          :active-step="4"
        />
        <!-- Title -->
        <div class="d-flex justify-content-center align-items-center">
          <div class="d-flex px-5 position-relative align-items-center">
            <h1 class="m-0">
              {{ $t('pages.ideaSettings.title') }}
            </h1>
            <i v-if="loadingOnOpenDialog" class="el-icon-loading h2" />
          </div>
        </div>
        <!-- /Title -->

        <!-- Subtitle -->
        <p class="text-lead m-0">
          {{ $t('pages.ideaSettings.subtitle') }}
        </p>
        <!-- /Subtitle -->
      </template>

      <!-- Dialog Body -->
      <ib-dialog-container class="py-2">
        <div class="dialog-body">

          <!-- Form Wrapper -->
          <div class="idea-settings-card">
            <v-app>
              <v-row :gutter="10">

                <!-- Illustration & Category Icon -->
                <v-col :cols="12" :md="5">

                  <!-- Illustration Update Image -->
                  <div class="illustration-wrapper m-auto">

                    <!-- Icon Wrapper -->
                    <template v-if="!startFromScratch">
                      <div
                        v-if="form.image === null && form.croppedImage === null && form.uploadedImage === null && idea.icon"
                        :style="{ backgroundImage: imageUrl(idea.icon) || imageUrl(category.icon) }"
                        class="icon-wrapper"
                      />
                    </template>
                    <!-- /Icon Wrapper -->

                    <!-- Image Uploader -->
                    <div class="idea-image-uploader">

                      <!-- Avatar -->
                      <div
                        v-show="form.image !== null || form.croppedImage !== null"
                        class="avatar-container"
                        :class="{'disable': loadingOnOpenDialog}"
                        :style="cardImage(form.image ? form.image.path + form.image.filename : form.croppedImage)"
                        @click="deleteImage"
                      >
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M15 4h4a1 1 0 110 2h-1v13a3 3 0 01-3 3H5a3 3 0 01-3-3V6H1a1 1 0 110-2h4V3a3 3 0 013-3h4a3 3 0 013 3v1zm-2 0V3a1 1 0 00-1-1H8a1 1 0 00-1 1v1h6zm3 2H4v13a1 1 0 001 1h10a1 1 0 001-1V6zm-9 4a1 1 0 112 0v6a1 1 0 11-2 0v-6zm4 0a1 1 0 112 0v6a1 1 0 11-2 0v-6z"/></svg>
                        </span>
                      </div>
                      <!-- /Avatar -->

                      <!-- Uploader -->
                      <div v-show="!(form.image !== null || form.croppedImage !== null)">
                        <ib-tooltip
                          placement="top-end"
                          popper-class="medium center"
                          content="Upload your own picture or logo"
                        >
                          <!-- Plus Button -->
                          <div
                            v-show="!form.uploadedImage"
                            class="plus"
                            :class="{'disable': loadingOnOpenDialog}"
                            @click="openImageUploader"
                          >
                            <i class="el-icon-plus avatar-uploader-icon" />
                          </div>
                          <!-- /Plus Button -->
                        </ib-tooltip>

                        <!-- Image Input -->
                        <label
                          v-if="form.uploadedImage === null"
                          :style="{ backgroundImage: imageUrl(idea.cover) || imageUrl(category.cover) }"
                          class="upload-label"
                          for="upload-img"
                          style="background-size: contain;"
                        >
                          <input
                            id="upload-img"
                            ref="imageUploader"
                            :disabled="loadingOnOpenDialog"
                            class="avatar-uploader-icon"
                            :class="{'disable': loadingOnOpenDialog}"
                            type="file"
                            accept="image/*"
                            @change="setImage"
                          >
                        </label>
                        <!-- /Image Input -->
                        <!-- Vue Cropper -->
                        <vue-cropper
                          v-show="form.uploadedImage"
                          ref="cropper"
                          :class="{ 'unhidden': form.uploadedImage }"
                          class="cropper"
                          :guides="true"
                          :view-mode="0"
                          drag-mode="crop"
                          :auto-crop-area="1"
                          :min-container-width="335"
                          :min-container-height="335"
                          :background="true"
                          :output-type="'png'"
                          :src="form.uploadedImage"
                          alt=""
                          :img-style="{ 'width': '335px', 'height': '335px' }"
                        />
                        <!-- /Vue Cropper -->
                      </div>
                      <!-- /Uploader -->
                    </div>
                    <!-- Set Button & Instructions -->
                    <!--                  <div-->
                    <!--                    v-if="form.uploadedImage && $breakpoint.xl"-->
                    <!--                    class="image-setter d-flex align-items-center"-->
                    <!--                  >-->
                    <!--                    &lt;!&ndash; Set Button &ndash;&gt;-->
                    <!--                    <el-button-->
                    <!--                      class="small mr-3 mr-lg-4"-->
                    <!--                      type="default"-->
                    <!--                      @click="cropImage"-->
                    <!--                    >-->
                    <!--                      Set-->
                    <!--                    </el-button>-->
                    <!--                    &lt;!&ndash; /Set Button &ndash;&gt;-->

                    <!--                    &lt;!&ndash; Instructions &ndash;&gt;-->
                    <!--                    <p>-->
                    <!--                      {{ instructionText }}-->
                    <!--                    </p>-->
                    <!--                  &lt;!&ndash; /Instructions &ndash;&gt;-->
                    <!--                  </div>-->
                    <!-- /Set Button & Instructions -->
                    <!-- /Image Uploader -->
                  </div>
                  <!-- /Illustration Update Image -->

                  <!-- Idea Name -->
                  <h2 class="text-center text-md-left h4">
                    {{ form.name }}
                  </h2>
                  <!-- /Idea Name -->

                  <!-- Category Name -->
                  <p v-if="updateIdeaSettings" class="o-50 m-0 text-center text-md-left">
                    {{ idea.name }}
                  </p>
                  <!-- /Category Name -->
                </v-col>
                <!-- /Illustration & Category Icon -->

                <!-- Form -->
                <v-col :cols="12" :md="7" class="form-fields-wrapper">
                  <v-form>
                    <v-row dense>

                      <!-- Idea Name -->
                      <v-col :cols="12" class="mb-1">
                        <v-text-field
                          v-model="form.name"
                          :label="`${$t('pages.ideaSettings.ideaName')} *`"
                          :disabled="loadingOnOpenDialog"
                          :error="showValidationErrors && !form.name"
                          outlined
                          maxlength="50"
                          :hide-details="true"
                          dense
                          height="52px"
                          @blur="onIdeaNameBlur"
                          @keyup.enter.native="updateSettings ? onEditIdea() : onSaveIdea()"
                        />
                      </v-col>
                      <!-- /Idea Name -->

                      <!-- Description -->
                      <v-col :cols="12" class="my-1">
                        <v-textarea
                          v-model="form.description"
                          :disabled="loadingOnOpenDialog"
                          :error="showValidationErrors && !form.description"
                          :rows="2"
                          :label="`${$t('pages.ideaSettings.ideaDescription')} *`"
                          outlined
                          :hide-details="true"
                          maxlength="500"
                        />
                      </v-col>
                      <!-- /Description -->

                      <!-- Country -->
                      <v-col :cols="12" :md="6" class="my-1">
                        <v-autocomplete
                          v-model="form.country"
                          :label="`${$t('pages.ideaSettings.ideaCountry')} *`"
                          :items="countries"
                          outlined
                          :error="showValidationErrors && !form.country"
                          :disabled="loadingOnOpenDialog"
                          :hide-details="true"
                          item-value="code"
                          item-text="name"
                          dense
                          height="52px"
                        />
                      </v-col>
                      <!-- /Country -->

                      <!-- City -->
                      <v-col :cols="12" :md="6" class="my-1">
                        <v-text-field
                          v-model="form.city"
                          :label="$t('pages.ideaSettings.ideaCity')"
                          :disabled="loadingOnOpenDialog"
                          outlined
                          maxlength="50"
                          :hide-details="true"
                          dense
                          height="52px"
                          @blur="onIdeaNameBlur"
                          @keyup.enter.native="updateSettings ? onEditIdea() : onSaveIdea()"
                        />
                      </v-col>
                      <!-- /City -->

                      <!-- Stage -->
                      <v-col :cols="12" class="my-1">
                        <v-select
                          v-model="form.stage"
                          :label="`${$t('pages.ideaSettings.ideaStage')} *`"
                          :items="stages"
                          outlined
                          :error="showValidationErrors && !form.stage"
                          :disabled="loadingOnOpenDialog"
                          :hide-details="true"
                          item-value="type"
                          :item-text="item => $t(item.title)"
                          dense
                          :menu-props="{offsetY: true}"
                          height="52px"
                        />
                      </v-col>
                      <!-- /Stage -->

                      <!-- Idea Type -->
                      <v-col :cols="12" class="my-1">
                        <v-autocomplete
                          v-model="catalogueIdeaId"
                          :label="`${$t('pages.ideaSettings.ideaType')} *`"
                          :items="catalogueOptionsFormatted"
                          outlined
                          :error="showValidationErrors && !catalogueIdeaId"
                          :disabled="loadingOnOpenDialog"
                          :hide-details="true"
                          item-value="id"
                          :item-text="item => item.options ? item.options[currentLang].name : item.name"
                          dense
                          height="52px"
                        />
                      </v-col>
                      <!-- /Idea Type -->

                      <!-- Slogan -->
                      <v-col :cols="12" class="my-1">
                        <v-textarea
                          v-model="form.slogan"
                          :disabled="loadingOnOpenDialog"
                          :rows="2"
                          :placeholder="$t('pages.ideaSettings.addSlogan')"
                          outlined
                          :hide-details="true"
                          maxlength="500"
                        />
                      </v-col>
                      <!-- /Slogan -->
                    </v-row>

                    <!-- Currency & Number Format -->
                    <v-row class="pt-0 my-1" dense>
                      <!-- Currency -->
                      <v-col :cols="12" class="my-1">
                        <div class="d-flex mb-1">
                          <p class="text-lead my-0 mr-1">
                            {{ $t('pages.financialPlan.financialSettings') }}
                          </p>
                          <!--                          <ib-tooltip-->
                          <!--                            placement="top-start"-->
                          <!--                            popper-class="large left"-->
                          <!--                            :content="financialSettingsHint"-->
                          <!--                            on-dialog-->
                          <!--                          >-->
                          <!--                            <i class="ib-icon-info" />-->
                          <!--                          </ib-tooltip>-->
                        </div>
                        <v-autocomplete
                          v-model="form.currency"
                          :items="currencies"
                          item-value="code"
                          item-text="name"
                          outlined
                          :disabled="loadingOnOpenDialog"
                          :error="showValidationErrors && !form.currency"
                          :label="`${$t('pages.financialPlan.settings.currency')} *`"
                          :hide-details="true"
                          dense
                          height="52px"
                        >
                          <template #item="{item}">
                            <span style="font-size: 13px;">{{ item.name }} ({{ item.symbol }})</span>
                          </template>
                          <template #selection>
                            <span>{{ selectedCurrency.name }} ({{ selectedCurrency.symbol }})</span>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <!-- /Currency -->
                    </v-row>
                    <!-- /Currency & Number Format -->

                    <!-- Starting From & Planning For -->
                    <v-row dense>
                      <!-- Starting From -->
                      <v-col :cols="12" :md="6" class="my-1">
                        <v-menu
                          ref="menu"
                          v-model="dateStartingFromMenuVisible"
                          :close-on-content-click="false"
                          offset-y
                          max-width="243px"
                          min-width="243px"
                        >
                          <template #activator="{ on, attrs }">
                            <v-text-field
                              :value="formatDateForStartingFrom"
                              :label="`${$t('pages.financialPlan.settings.planningStart')} *`"
                              readonly
                              outlined
                              :disabled="loadingOnOpenDialog"
                              :hide-details="true"
                              :append-icon="icons.mdiCalendarMonthOutline"
                              v-bind="attrs"
                              dense
                              height="52px"
                              v-on="on"
                              @click:append="dateStartingFromMenuVisible = true"
                            />
                          </template>
                          <v-date-picker
                            v-model="form.startingFrom"
                            :width="243"
                            color="primary"
                            type="month"
                            :min="minDate"
                            :max="maxDate"
                            scrollable
                            @change="dateStartingFromMenuVisible = false"
                          />
                        </v-menu>
                      </v-col>
                      <!-- /Starting From -->

                      <!-- Planning For -->
                      <v-col :cols="12" :md="6" class="my-1">
                        <v-select
                          v-model="form.planningFor"
                          :items="planningForOptions"
                          item-value="value"
                          item-text="label"
                          outlined
                          :disabled="loadingOnOpenDialog"
                          :label="`${$t('pages.ideaSettings.planningPeriod')} *`"
                          :menu-props="{offsetY: true}"
                          :hide-details="true"
                          :error="showValidationErrors && !form.planningFor"
                          dense
                          height="52px"
                        />
                      </v-col>
                      <!-- /Planning For -->
                    </v-row>
                    <!-- /Starting From & Planning For -->
                  </v-form>
                </v-col>
                <!-- /Form -->
              </v-row>
            </v-app>
          </div>
          <!-- /Form Wrapper -->
        </div>
      </ib-dialog-container>
      <!-- /Dialog Body -->

      <template #footer>
        <ib-dialog-container>
          <!--          <ib-divider class="mb-4" block />-->
          <div class="d-flex align-items-center justify-content-end idea-settings-footer">
            <ib-dialog-footer
              button-size="md"
              :submit-btn-text="updateSettings ? $t('applyChanges') : $t('letsStart')"
              :cancel-btn-text="updateSettings ? $t('cancel') : $t('chooseSomethingElse')"
              :submit-btn-class="!updateSettings ? 'gtm_onboarding-button-start' : null"
              :loading="loadingOnSaveIdea"
              :disable-submit-btn="loadingOnOpenDialog"
              @submit="updateSettings ? onEditIdea() : onSaveIdea()"
              @cancel="$router.go(-1)"
            />
          </div>
        </ib-dialog-container>
      </template>
    </ib-dialog>
    <!-- /Dialog -->
  </div>
</template>

<!--suppress JSUnusedGlobalSymbols -->
<script>
import 'cropperjs/dist/cropper.css'
import EventBus from '@/event-bus'
import MixinCountries from '@/mixins/countries'
import MixinCurrencies from '@/mixins/currencies'
import MixinUser from '@/mixins/user'
import StepperPagination from '@/components/_v2/StepperPagination'
import _find from 'lodash/find'
import ideaStages from '@/data/ideaStages'
import { mdiCalendarMonthOutline } from '@mdi/js'

import IdeaTypesEnum, { getTypeNames } from '@/constants/IdeaTypesEnum'
import { mapActions, mapGetters } from 'vuex'
import VueCropper from 'vue-cropperjs'
import _sortBy from 'lodash/sortBy'
import { justWannaTryData } from '@/data/onboarding'

export default {
  name: 'DialogIdeaSettings',

  components: {
    StepperPagination,
    VueCropper
  },

  mixins: [
    MixinCountries,
    MixinCurrencies,
    MixinUser
  ],

  props: {
    visible: Boolean,
    idea: {
      default: null,
      type: Object
    },
    category: {
      default: null,
      type: Object
    },
    updateIdeaSettings: {
      default: false,
      type: Boolean
    },
    template: {
      type: Boolean,
      default: false
    },
    templateOnCurrentLanguage: {
      type: Boolean,
      default: false
    },
    startFromScratch: {
      type: Boolean,
      default: false
    },
    ideaType: {
      type: Number,
      default: IdeaTypesEnum.STARTUP_SMALL_BUSINESS
    },
    catalogue: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      dateStartingFromMenuVisible: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      loadingOnSaveIdea: false,
      loadingOnOpenDialog: false,
      disabledToolTip: false,
      showValidationErrors: false,
      showCatalogueEditor: false,
      form: {
        name: '',
        slogan: '',
        currency: 'USD',
        numberFormat: '10,000.00',
        startingFrom: this.$moment().format('YYYY-MM'),
        planningFor: '',
        catalogueCategoryId: null,
        catalogueIdeaId: null,
        ideaCatalogueCategoryId: null,
        ideaCatalogueIdeaId: null,
        image: null,
        uploadedImage: null,
        croppedImage: null,
        template: null,
        stage: null,
        description: null,
        country: null,
        city: null
      },
      oldStartingFrom: null,
      oldPlaningFor: null,
      focus: {
        name: false,
        startingFrom: false,
        planningFor: false
      },
      catalogueOptions: null,
      updateSettings: this.updateIdeaSettings,
      instructionText: 'Zoom image using the mouse wheel. Pinch to zoom for touchscreens.',
      icons: { mdiCalendarMonthOutline }
    }
  },

  computed: {
    ...mapGetters('ideaCollection', [
      'getIdeasCount',
      'getSharedIdeasCount'
    ]),

    ...mapGetters('idea', ['isIdeaFromTemplate', 'getCatalogueIdeaTemplate']),

    stages () {
      return ideaStages
    },

    currentLang () {
      return localStorage.getItem('lang')
    },

    selectedCurrency () {
      return _find(this.currencies, ['code', this.form.currency])
    },

    hasIdeasOrSharedIdeas () {
      return this.getIdeasCount || this.getSharedIdeasCount
    },

    continueDisabled () {
      const formCopy = Object.assign({}, this.form)
      delete formCopy.slogan
      delete formCopy.catalogueIdeaId
      delete formCopy.image
      delete formCopy.croppedImage
      delete formCopy.uploadedImage
      delete formCopy.template
      delete formCopy.city

      if (this.startFromScratch) {
        delete formCopy.catalogueCategoryId
      }

      return Object.values(formCopy).some(x => (x === null || x === ''))
    },

    isTemplateAdmin () {
      return this.$store.getters['user/isTemplateAdmin']
    },

    financialSettingsHint () {
      return this.updateSettings ? this.$t('pages.ideaSettings.financialSettingsHint') : this.$t('pages.ideaSettings.onboardingFinancialSettingsHint')
    },

    catalogueIdeaId: {
      get () {
        return this.loadingOnOpenDialog ? this.idea.name === '' ? null : this.idea.name : this.form.catalogueIdeaId
      },
      set (val) {
        this.form.catalogueIdeaId = val
      }
    },

    catalogueIdeaTemplate () {
      const currentLang = localStorage.getItem('lang')

      if (this.template && this.templateOnCurrentLanguage) {
        return _find(this.idea.templates, ['lang', currentLang])
      }

      const found = _find(this.idea.templates, ['lang', 'en'])

      return found || {}
    },

    catalogueOptionsFormatted () {
      const items = []

      if (this.catalogueOptions) {
        const catalogueOptions = _sortBy(this.catalogueOptions, ['position'])

        catalogueOptions.forEach(item => {
          items.push({ header: item.options[this.currentLang].name })

          for (const index of Object.keys(item.children)) {
            items.push(item.children[index])
          }
        })
      }

      return items
    },

    planningForOptions () {
      return [
        {
          value: 1,
          label: this.$t('oneYear')
        },
        {
          value: 2,
          label: this.$t('twoYear')
        },
        {
          value: 3,
          label: this.$t('threeYear')
        },
        {
          value: 5,
          label: this.$t('fiveYear')
        }
      ]
    },

    minDate () {
      return this.$moment().startOf('year').subtract(3, 'y').format('YYYY-MM-DD')
    },

    maxDate () {
      return this.$moment().startOf('year').add(4, 'y').format('YYYY-MM-DD')
    },

    formatDateForStartingFrom () {
      return this.$moment(this.form.startingFrom).format('MMMM, yyyy')
    }
  },

  watch: {
    'form.name' () {
      this.form.name = this.form.name.replace(/^\s+/g, '')
    },
    'form.catalogueIdeaId' (val) {
      if (this.catalogue) {
        this.catalogue.forEach(item => {
          const found = _find(item.children, ['id', val])
          if (found) {
            this.form.catalogueCategoryId = item.id
          }
        })
      }

      if (typeof val === 'string') {
        const split = this.form.catalogueIdeaId.split('-')
        this.form.catalogueCategoryId = Number(split[0])
      }
    },
    visible: {
      handler (isVisible) {
        if (isVisible && this.$route.name !== 'select-idea') {
          this.loadingOnOpenDialog = true
          this.getIdeaSettings().then((response) => {
            this.onDialogOpen()
            this.setupCatalogue(response.catalogue)
            this.loadingOnOpenDialog = false
          }
          ).catch(() => { this.loadingOnOpenDialog = false })
        }

        if (isVisible && this.$route.name === 'select-idea') {
          this.setupCatalogue(this.catalogue)
        }
      },
      immediate: true
    },
    '$breakpoint.xl' (value) {
      if (!value && this.form.uploadedImage) {
        this.cropImage()
      }
    }
  },

  mounted () {
    this.onDialogOpen()
  },

  methods: {
    ...mapActions('idea', [
      'getIdeaSettings'
    ]),

    onSaveIdea () {
      if (this.continueDisabled) {
        this.showValidationErrors = true

        return
      }
      if (this.$refs.cropper.getCroppedCanvas() !== null && this.form.uploadedImage !== null) {
        this.cropImage()
      }
      this.loadingOnSaveIdea = true

      this.$http.post('ideas', { ...this.form, template: this.template, type: this.ideaType, catalogueIdeaTemplate: this.catalogueIdeaTemplate, startingFrom: `${this.form.startingFrom}-01` })
        .then((response) => {
          if (response.status === 201) {
            this.$store.commit('user/increaseIdeasCount')
            this.$store.commit('idea/setIdea', { idea: response.data.payload.idea })
            this.$store.commit('ideaCollection/addIdea', response.data.payload.generalInfoIdea)
            this.$emit('close-dialog', true)
            this.loadingOnSaveIdea = false

            this.intercomUpdateUser()
            this.$intercom.trackEvent('Idea created')
            this.$gtm.trackEvent({
              event: 'newIdeaCreated'
            })

            if (this.firstTime) {
              const typeName = getTypeNames(this.ideaType)
              this.$gtm.trackEvent({
                event: 'onboarding-finished',
                value: typeName
              })
            }
          }
        }).catch(e => {
        // TODO - Show error message
          this.loadingOnSaveIdea = false
        })
    },

    onEditIdea () {
      if (this.continueDisabled || !this.catalogueIdeaId) {
        this.showValidationErrors = true

        return
      }
      if (this.$refs.cropper.getCroppedCanvas() !== null && this.form.uploadedImage !== null) {
        this.cropImage()
      }
      this.loadingOnSaveIdea = true

      this.$http.put(`ideas/${this.$store.state.idea.id}`, { ...this.form, startingFrom: `${this.form.startingFrom}-01` })
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/setIdea', { idea: response.data.payload.idea })
            this.$store.commit('ideaCollection/updateIdea', response.data.payload.generalInfoIdea)
            this.$emit('idea-saved')

            if ((this.oldPlaningFor !== this.form.planningFor) || (`${this.oldStartingFrom}-01` !== this.form.startingFrom)) {
              EventBus.$emit('idea-settings-update')
            }
          }
          this.$router.go(-1)
          this.loadingOnSaveIdea = false
          this.$notify({ title: this.$t('success'), message: this.$t('success'), type: 'success' })
        }).catch(() => {
          this.loadingOnSaveIdea = false
        })
    },

    onDialogOpen () {
      const { ideaSettings, catalogueCategory, catalogueIdea, slogan, name } = this.$store.state.idea
      if (!this.updateIdeaSettings) {
        const typeName = getTypeNames(this.ideaType)
        this.$router.replace({ query: this.$route.query, hash: `#${typeName}-ideasettings` })
      }

      this.form.name = ''
      if (!this.startFromScratch) {
        this.form.catalogueCategoryId = this.idea.category
        this.form.catalogueIdeaId = this.idea ? this.idea.id : `${this.category.id}-other`
      }

      this.form.ideaCatalogueCategoryId = this.idea.category
      this.form.ideaCatalogueIdeaId = this.idea ? this.idea.id : `${this.category.id}-other`

      if (this.updateIdeaSettings) {
        this.form.name = name
        if (ideaSettings.image) {
          this.form.image = ideaSettings.image
        } else {
          this.form.image = null
          this.form.croppedImage = null
          this.form.uploadedImage = null
        }

        this.form.stage = ideaSettings.stage
        this.form.country = ideaSettings.country
        this.form.city = ideaSettings.city
        this.form.description = ideaSettings.description

        this.form.planningFor = ideaSettings.planningFor
        this.form.startingFrom = this.$moment(ideaSettings.startingFrom).format('YYYY-MM')

        this.oldPlaningFor = ideaSettings.planningFor
        this.oldStartingFrom = this.$moment(ideaSettings.startingFrom).format('YYYY-MM')

        this.form.currency = ideaSettings.currency
        this.form.slogan = slogan
        this.form.catalogueCategoryId = catalogueCategory.id
        this.form.catalogueIdeaId = catalogueIdea ? catalogueIdea.id : `${this.category.id}-other`

        this.form.ideaCatalogueCategoryId = catalogueCategory.id
        this.form.ideaCatalogueIdeaId = catalogueIdea ? catalogueIdea.id : `${this.category.id}-other`
      } else if (this.ideaType === IdeaTypesEnum.JUST_WANNA_TRY) {
        this.form.name = justWannaTryData.ideaSettings.name
        this.form.planningFor = justWannaTryData.ideaSettings.planningFor
      }

      this.checkInputLabels()
    },

    onIdeaNameBlur () {
      this.toggleFocus('name')
      this.$gtm.trackEvent({
        event: 'ideaName'
      })
    },

    toggleFocus (input, type = '') {
      type === 'blur' ? this.disabledToolTip = false : this.disabledToolTip = true
      this.focus[input] = !this.focus[input]
      if (this.form[input]) {
        this.focus[input] = true
      }
    },

    setupCatalogue (catalogue) {
      this.catalogueOptions = catalogue
    },

    checkInputLabels () {
      this.checkInputLength('name')
      this.checkInputLength('currency')
      this.checkInputLength('startingFrom')
      this.checkInputLength('planningFor')
    },

    checkInputLength (input) {
      this.focus[input] = !!this.form[input]
    },

    onShowCatalogueEditor () {
      if (this.loadingOnOpenDialog) return
      if (this.catalogueOptions) {
        this.showCatalogueEditor = true
      }
    },

    closeDialog () {
      this.form.catalogueCategoryId = null
      this.form.catalogueIdeaId = null
      this.$emit('close-dialog')
    },

    onDialogClosed () {
      this.showCatalogueEditor = false
      this.$emit('dialog-closed')
    },

    openImageUploader () {
      this.$refs.imageUploader.click()
    },

    setImage (e) {
      const file = e.target.files[0]

      if (!file.type.includes('image/')) {
        alert('Please select an image file')

        return
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.form.uploadedImage = event.target.result
          if (this.$isTouchDevice && !this.$breakpoint.xl) {
            this.form.croppedImage = event.target.result
          } else {
            this.$refs.cropper.replace(event.target.result)
          }
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },

    deleteImage () {
      if (!this.loadingOnOpenDialog) {
        this.form.image = null
        this.form.uploadedImage = null
        this.form.croppedImage = null
      }
    },

    cropImage () {
      const imageBase64 = this.$refs.cropper.getCroppedCanvas().toDataURL()

      if (imageBase64.startsWith('data:image/')) {
        this.form.croppedImage = imageBase64
        this.form.uploadedImage = null

        return
      }

      this.deleteImage()
    },

    cardImage (img) {
      return img ? 'background-image: url(' + img + ')' : ''
    },

    imageUrl (img) {
      if (this.startFromScratch) {
        return `url('${require('@/assets/img/illustrations/select-idea/startup-small-business.png')}')`
      }

      return img ? `url('${this.baseUrl}${img.path}${img.filename}')` : ''
    },

    intercomUpdateUser () {
      const catalogueIdeaTemplate = this.getCatalogueIdeaTemplate

      let data = {
        IdeaCount: this.getIdeasCount,
        Template: this.isIdeaFromTemplate
      }

      if (catalogueIdeaTemplate) {
        data = {
          ...data,
          TemplateId: catalogueIdeaTemplate.id,
          TemplateName: catalogueIdeaTemplate.catalogueIdeaName,
          TemplateLang: catalogueIdeaTemplate.lang
        }
      }

      this.$intercom.update(data)
    }
  }
}
</script>
<style scoped lang="scss">
  .el-icon-loading {
    position: absolute;
    right: 10px;
  }

  .dialog-idea-settings {
    ::v-deep {
      .el-dialog {

        .el-tooltip__popper {
          z-index: 1000000001 !important
        }
      }
    }
  }

  .idea-settings-card {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    padding: 40px 20px;
  }

  .idea-settings-footer {
    margin-top: -30px;
  }

</style>
